// import React from "react"
import React, { useState, useEffect } from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import { navigate } from "gatsby"
// import { Form, Formik, input } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import axios from "axios"
import OurFeatures from "../../components/Index/OurFeatures"
import wlogo from "../../assets/images/wlogo.svg"

const PartnerLandingPage = props => {
  const [SubmitSuccess, setSubmitSuccess] = useState(false)
  const [Failure, setFailure] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (SubmitSuccess || Failure) {
      const timer = setTimeout(() => {
        setSubmitSuccess(false)
        setFailure(false)
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [SubmitSuccess, Failure])

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    subject: "What are you interested in?",
    postalCode: "",
    LoanAmount: "",
    requestedDate: "",
    requestedTimeSlot: "",
    termsConditions: false,
    ReferralPartner: "Facebook",
  }

  const errorMsg = {
    color: "red",
    textAlign: "left",
    padding: "5px",
  }

  const ValidateEmail = value => {
    let error
    if (!value) {
      return (error = "Please Enter Your Email Address")
    }
    const RegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!RegEx.test(value)) {
      error = "Please Enter a Valid Email Address"
    }
    return error
  }

  const onSubmit = async (values, { resetForm }) => {
    values.ReferralPartner = "Facebook"
    console.log("submit", values)
    try {
      setLoading(true)
      if (values.LoanAmount) {
        values.LoanAmount = parseFloat(values.LoanAmount)
      } else {
        delete values.LoanAmount
      }
      await axios.post(
        `${process.env.GATSBY_BACKEND_API_URL}/appointments/contacts`,
        values,
        {}
      )
      setSubmitSuccess(true)
      resetForm(initialValues)
      if (window.pageYOffset !== 0) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      }
      setLoading(false)
      navigate("/partners/facebook-form-submitted")
    } catch (error) {
      console.log("error", error)
      setSubmitSuccess(false)
      setFailure(true)
      setLoading(false)
      resetForm(initialValues)
    }
  }

  return (
    <Layout>
      <SEO title="Oxygen | Home Loans" />
      <div className="whitelogofb">
        <a href="/">
          {" "}
          <img src={wlogo} alt="logo" />{" "}
        </a>
      </div>
      <div className="page-partner-landing">
        <div className="ld-content-wrapper">
          <div className="ld-content">
            <div className="text-content">
              <h2 className="mt-1">Grate rates start here</h2>
              <div className="para">
                From free guid to saving money on your home loan, compleate the
                form below.
              </div>

              {/* <h2>Thank you!</h2>
                <div>Your guid is on the way.</div>
              <div className="para">Last month, our coustomers saved an average of $243 on their home loan payments. You can too!</div> */}
            </div>

            <div id="frmcontact">
              <div className="row">
                <div className="col-md-12">
                  <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    <Form className="frm-partner-ld" id="PartnerLandingPage">
                      <div className="form-group">
                        <Field
                          // type="email"
                          className="form-control"
                          placeholder="Your email address"
                          name="email"
                          id="email"
                          validate={ValidateEmail}
                          // required
                        />
                        <div className="error-msg-fb-prt">
                          <ErrorMessage name="email">
                            {msg => <div style={errorMsg}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="default-btn btn-fb-landing mb-3 mx-2"
                      >
                        {/* <i className="flaticon-user"></i> */}
                        Get Started{" "}
                        <svg
                          className="mb-0"
                          height="25"
                          viewBox="0 0 1792 1792"
                          width="25"
                          fill="#ffffff"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z" />
                        </svg>
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-page-partner">
        <OurFeatures />
      </div>
    </Layout>
  )
}

export default PartnerLandingPage
