import React from "react"

const OurFeatures = () => {
  return (
    <section className="services-area pb-5">
      <div className="container">
        <div className="row d-flex justify-stats">
          <div className="col-12 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box gradient">
              <h3>$10+ Billion</h3>
              <p>in home loans settled</p>
            </div>
          </div>

          <div className="col-12 col-sm-4  col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box gradient">
              <h3>27,000+</h3>
              <p>customers served</p>
            </div>
          </div>

          <div className="col-12 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box gradient">
              <h3>19 Years</h3>
              <p>of service and counting</p>
            </div>
          </div>

          <div className="col-12 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box gradient">
              <h3>8x Winner</h3>
              <p>MFAA &amp; AFG Brokerage of the Year</p>
            </div>
          </div>

          <div className="col-12 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box gradient">
              <h3>53+</h3>
              <p>Locations + Apply Online</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurFeatures
